import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./header.css";
import { Logo } from "./components/logo";
import { WithSeparator } from "./components/with-separator";
import { useAuth } from "./auth-context";
import { useTranslation } from "react-i18next";
function getCurrentMenuLinks(username) {
    return [
        [
            {
                to: "/view/categories",
                text_en: "View Maps",
                text_gr: "Προβολή Χαρτών",
            },
            {
                to: "/edit/categories",
                text_en: "Edit Maps",
                text_gr: "Επεξεργασία Χαρτών",
            },
            {
                // to: "/data-extracts.html",
                to: '',
                text_en: "Download data",
                text_gr: "Λήψη δεδομένων",
                disabled: true,
                note: "Coming soon",
            },
            {
                to: "https://github.com/colouring-cities/colouring-Athens",
                text_en: "Access open code",
                text_gr: "Πρόσβαση ανοιχτού κώδικα",
                external: true,
            },
            {
                to: "/showcase.html",
                text_en: "View Data Showcase",
                text_gr: "Προβολή έκθεσης δεδομένων",
                disabled: true,
                note: "Coming soon",
            },
        ],
        [
            {
                // to: "https://pages.colouring.london",
                to: "/about-page.html",
                text_en: "About",
                text_gr: "Σχετικά",
                // external: true,
            },
            {
                // to: "https://pages.colouring.london/buildingcategories",
                to: "/data-categories.html",
                text_en: "Data Categories",
                text_gr: "Κατηγορίες δεδομένων",
                // external: true,
            },
            {
                // to: "https://pages.colouring.london/whoisinvolved",
                to: "/who-is-envolved.html",
                text_en: "Who's Involved?",
                text_gr: "Ποιοι εμπλέκονται;",
                // external: true,
            },
            {
                // to: "https://pages.colouring.london/data-ethics",
                to: "/data-ethics.html",
                text_en: "Data Ethics",
                text_gr: "Δεοντολογία Δεδομένων",
                // external: true,
            },
            {
                // to: "https://pages.colouring.london/colouring-cities",
                to: "/ccrp.html",
                text_en: "Colouring Cities Research Programme",
                text_gr: "Colouring Cities Research Programme",
                // external: true,
            },
        ],
        [
            {
                to: "/leaderboard.html",
                text_en: "Top Contributors",
                text_gr: "Κορυφαίοι Συντελεστές",
            },
            {
                to: "https://github.com/colouring-cities/colouring-Athens/discussions",
                text_en: "Discussion Forum",
                text_gr: "Φόρουμ συζήτησης",
                external: true,
            },
        ],
        [
            {
                to: "/privacy-policy.html",
                text_en: "Privacy Policy",
                text_gr: "Πολιτική Απορρήτου",
            },
            {
                to: "/contributor-agreement.html",
                text_en: "Contributor Agreement",
                text_gr: "Συμφωνία συνεισφέροντος",
            },
            {
                to: "/code-of-conduct.html",
                text_en: "Code of Conduct",
                text_gr: "Κώδικας δεοντολογίας",
            },
            {
                to: "/data-accuracy.html",
                text_en: "Data Accuracy Agreement",
                text_gr: "Συμφωνία Ακρίβειας Δεδομένων",
            },
            // {
            //     to: "/ordnance-survey-uprn.html",
            //     text: "Ordnance Survey terms of UPRN usage"
            // },
        ],
        [
            {
                to: "/contact.html",
                text_en: "Contact",
                text_gr: "Επικοινωνία",
            },
            ...(username != undefined
                ? [
                    {
                        to: "/my-account.html",
                        text_en: `Account (${username})`,
                        text_gr: `Λογαριασμός (${username})`,
                    },
                ]
                : [
                    {
                        to: "/login.html",
                        text_en: "Log in",
                        text_gr: "Σύνδεση",
                    },
                    {
                        to: "/sign-up.html",
                        text_en: "Sign up",
                        text_gr: "Εγγραφή",
                    },
                ]),
        ],
    ];
}
const Menu = ({ onNavigate }) => {
    const { user } = useAuth();
    const { i18n } = useTranslation();
    const menuLinkSections = getCurrentMenuLinks(user === null || user === void 0 ? void 0 : user.username);
    return (React.createElement(WithSeparator, { separator: React.createElement("hr", null) }, menuLinkSections.map((section, idx) => (React.createElement("ul", { key: `menu-section-${idx}`, className: "navbar-nav flex-container" }, section.map((item) => (React.createElement("li", { className: "nav-item", key: `${item.to}-${i18n.language === "gr" ? item.text_gr : item.text_en}` }, item.disabled ? (React.createElement(LinkStub, { note: item.note }, i18n.language === "gr" ? item.text_gr : item.text_en)) : item.external ? (React.createElement(ExternalNavLink, { to: item.to }, i18n.language === "gr" ? item.text_gr : item.text_en)) : (React.createElement(InternalNavLink, { to: item.to, onClick: onNavigate }, i18n.language === "gr" ? item.text_gr : item.text_en))))))))));
};
const InternalNavLink = ({ to, onClick, children, }) => (React.createElement(NavLink, { className: "nav-link", to: to, onClick: onClick }, children));
const ExternalNavLink = ({ to, children }) => (React.createElement("a", { className: "nav-link", href: to }, children));
const LinkStub = ({ note, children }) => (React.createElement("a", { className: "nav-link disabled" },
    children,
    React.createElement("span", { className: "link-note" }, note)));
let ls;
if (typeof window !== "undefined") {
    ls = window.localStorage;
}
else {
    ls = {
        i18nextLng: "gr",
    };
}
export const Header = ({ animateLogo }) => {
    const [collapseMenu, setCollapseMenu] = useState(true);
    const toggleCollapse = () => setCollapseMenu(!collapseMenu);
    const handleNavigate = () => setCollapseMenu(true);
    const { t, i18n } = useTranslation();
    const lngs = {
        en: { nativeName: "EN" },
        gr: { nativeName: "GR" },
    };
    const [selected, setSelected] = React.useState(null);
    const handleChange = (s) => {
        i18n.changeLanguage(s.target.value);
        localStorage.setItem("language", i18n.language);
        setSelected(s.target.value);
    };
    React.useEffect(() => {
        setSelected(i18n.language);
    });
    return (React.createElement("header", { className: "main-header navbar navbar-light" },
        React.createElement("div", { className: "nav-header" },
            React.createElement(NavLink, { to: "/" },
                React.createElement(Logo, { variant: animateLogo ? "animated" : "default" })),
            React.createElement("select", { className: "custom-select", style: { width: 64 }, value: selected || undefined, onChange: handleChange }, Object.keys(lngs).map((lng) => (React.createElement("option", { value: lng, key: lng }, lngs[lng].nativeName)))),
            React.createElement("button", { className: "navbar-toggler", type: "button", onClick: toggleCollapse, "aria-expanded": !collapseMenu, "aria-label": "Toggle navigation" },
                t("menu"),
                "\u00A0",
                collapseMenu ? (React.createElement("span", { className: "navbar-toggler-icon" })) : (React.createElement("span", { className: "close" }, "\u00D7")))),
        React.createElement("nav", { className: collapseMenu ? "collapse navbar-collapse" : "navbar-collapse" },
            React.createElement(Menu, { onNavigate: handleNavigate }))));
};
