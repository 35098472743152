import React from "react";
import { Link } from "react-router-dom";
import { dateReviver } from "../../helpers";
import { apiGet } from "../apiHelpers";
import { Translation } from "react-i18next";
export default class DataExtracts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            extracts: undefined,
            latestExtract: undefined,
            previousExtracts: undefined,
        };
    }
    async componentDidMount() {
        let data = await apiGet("/api/extracts", { jsonReviver: dateReviver });
        const extracts = data.extracts;
        this.setState({
            extracts: extracts,
            latestExtract: extracts[0],
            previousExtracts: extracts.slice(1),
        });
    }
    render() {
        return (React.createElement(Translation, null, (t, { i18n }) => (React.createElement("article", null,
            React.createElement("section", { className: "main-col" },
                React.createElement("h1", { className: "h2" }, t('data_extracts')),
                React.createElement("p", null, t('data_extracts_text_a')),
                React.createElement("p", null,
                    t('data_extracts_text_b'),
                    " ",
                    React.createElement("a", { href: "http://opendatacommons.org/licenses/odbl/" }, "Open Data Commons Open Database License"),
                    " ",
                    "(ODbL)",
                    t('data_extracts_text_c')),
                React.createElement("p", null, t('data_extracts_text_d')),
                React.createElement("p", null, t('data_extracts_text_e')),
                React.createElement("p", null,
                    t('data_extracts_text_f'),
                    " ",
                    React.createElement(Link, { to: "/data-accuracy.html" }, t('data_extracts_text_f2')),
                    " ",
                    t('data_extracts_text_g'),
                    " ",
                    React.createElement(Link, { to: "https://wiki.osmfoundation.org/wiki/Terms_of_Use", target: "_blank" }, t('data_extracts_text_g2')),
                    "."),
                this.state.extracts == undefined ? (React.createElement("p", null, t('data_extracts_text_i'))) : this.state.extracts.length === 0 ? (React.createElement("p", null, t('data_extracts_text_h'))) : null,
                this.state.latestExtract != undefined ? (React.createElement("div", null,
                    React.createElement("h1", { className: "h3" }, t('data_extracts_text_k')),
                    React.createElement(ExtractDownloadLink, Object.assign({}, this.state.latestExtract)))) : null,
                this.state.previousExtracts &&
                    this.state.previousExtracts.length > 0 ? (React.createElement("div", null,
                    React.createElement("h1", { className: "h3" }, t('data_extracts_text_l')),
                    React.createElement("ul", null, this.state.previousExtracts.map((e) => (React.createElement("li", null,
                        React.createElement(ExtractDownloadLink, Object.assign({}, e)))))))) : null)))));
    }
}
const ExtractDownloadLink = (props) => (React.createElement("p", null,
    React.createElement("a", { href: props.download_path },
        "Extracted on ",
        props.extracted_on.toDateString())));
