import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';
import { useRevisionId } from './api-data/use-revision';
import { useBuildingData } from './api-data/use-building-data';
import { useUserVerifiedData } from './api-data/use-user-verified-data';
import { useUrlBuildingParam } from './nav/use-url-building-param';
import { useUrlCategoryParam } from './nav/use-url-category-param';
import { useUrlModeParam } from './nav/use-url-mode-param';
import BuildingView from './building/building-view';
import Categories from './building/categories';
import { EditHistory } from './building/edit-history/edit-history';
import MultiEdit from './building/multi-edit';
import Sidebar from './building/sidebar';
import Welcome from './pages/welcome';
import { PrivateRoute } from './route';
import { useLastNotEmpty } from './hooks/use-last-not-empty';
import { defaultMapCategory } from './config/category-maps-config';
import { useMultiEditData } from './hooks/use-multi-edit-data';
import { useAuth } from './auth-context';
import { sendBuildingUpdate } from './api-data/building-update';
/**
 * Load and render ColouringMap component on client-side only.
 * This is because leaflet and react-leaflet currently don't work on the server
 * (leaflet assumes the presence of browser-specific global `window` variable).
 *
 * The previous solution involved installing react-leaflet-universal,
 * but that doesn't work with latest react-leaflet.
 *
 * The limitation is that ColouringMap needs to be the single entry point in the whole app
 * to all modules that import leaflet or react-leaflet.
 */
const ColouringMap = loadable(async () => (await import('./map/map')).ColouringMap, { ssr: false });
/** Returns first argument, unless it's equal to the second argument - then returns undefined */
function unless(value, unlessValue) {
    return value === unlessValue ? undefined : value;
}
/** Returns the new value, unless it is equal to the current value - then returns undefined */
function setOrToggle(currentValue, newValue) {
    if (newValue == undefined || newValue === currentValue) {
        return undefined;
    }
    else {
        return newValue;
    }
}
export const MapApp = props => {
    var _a;
    const { user } = useAuth();
    const [categoryUrlParam] = useUrlCategoryParam();
    const [currentCategory, setCategory] = useState();
    useEffect(() => setCategory(unless(categoryUrlParam, 'categories')), [categoryUrlParam]);
    const displayCategory = (_a = useLastNotEmpty(currentCategory)) !== null && _a !== void 0 ? _a : defaultMapCategory;
    const [selectedBuildingId, setSelectedBuildingId] = useUrlBuildingParam('view', displayCategory);
    const [building, updateBuilding, reloadBuilding] = useBuildingData(selectedBuildingId, props.building, user != undefined);
    const [userVerified, updateUserVerified, reloadUserVerified] = useUserVerifiedData(selectedBuildingId, props.user_verified);
    const [revisionId, updateRevisionId] = useRevisionId(props.revisionId);
    useEffect(() => {
        updateRevisionId(building === null || building === void 0 ? void 0 : building.revision_id);
    }, [building]);
    const [mode] = useUrlModeParam();
    const viewEditMode = unless(mode, 'multi-edit');
    const [multiEditData, multiEditError] = useMultiEditData();
    const selectBuilding = useCallback((selectedBuilding) => {
        const currentId = selectedBuildingId;
        updateBuilding(selectedBuilding);
        setSelectedBuildingId(setOrToggle(currentId, selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.building_id));
    }, [selectedBuildingId, setSelectedBuildingId, updateBuilding, building]);
    const colourBuilding = useCallback(async (building) => {
        const buildingId = building === null || building === void 0 ? void 0 : building.building_id;
        if (buildingId != undefined && multiEditError == undefined) {
            try {
                const updatedBuilding = await sendBuildingUpdate(buildingId, multiEditData);
                updateRevisionId(updatedBuilding.revision_id);
            }
            catch (error) {
                console.error({ error });
            }
        }
    }, [multiEditError, multiEditData, currentCategory]);
    const handleBuildingUpdate = useCallback((buildingId, updatedData) => {
        // only update current building data if the IDs match
        if (buildingId === selectedBuildingId) {
            updateBuilding(Object.assign({}, building, updatedData));
        }
        else {
            // otherwise, still update the latest revision ID
            updateRevisionId(updatedData.revision_id);
        }
    }, [selectedBuildingId, building, updateBuilding, updateRevisionId]);
    const handleUserVerifiedUpdate = useCallback((buildingId, updatedData) => {
        // only update current building data if the IDs match
        if (buildingId === selectedBuildingId) {
            updateUserVerified(Object.assign({}, userVerified, updatedData)); // quickly show added verifications
            reloadBuilding();
            reloadUserVerified(); // but still reload from server to reflect removed verifications
        }
    }, [selectedBuildingId, updateUserVerified, reloadBuilding, userVerified]);
    return (React.createElement(React.Fragment, null,
        React.createElement(PrivateRoute, { path: "/:mode(edit|multi-edit)" }),
        " ",
        React.createElement(Sidebar, null,
            React.createElement(Switch, null,
                React.createElement(Route, { exact: true, path: "/" },
                    React.createElement(Welcome, null)),
                React.createElement(Route, { exact: true, path: "/multi-edit/:cat" },
                    React.createElement(MultiEdit, { category: displayCategory })),
                React.createElement(Route, { path: "/:mode/:cat" },
                    React.createElement(Categories, { mode: mode || 'view', building_id: selectedBuildingId }),
                    React.createElement(Switch, null,
                        React.createElement(Route, { exact: true, path: "/:mode/:cat/:building/history" },
                            React.createElement(EditHistory, { building: building })),
                        React.createElement(Route, { exact: true, path: "/:mode/:cat/:building?" },
                            React.createElement(BuildingView, { mode: viewEditMode, cat: displayCategory, building: building, user_verified: userVerified !== null && userVerified !== void 0 ? userVerified : {}, onBuildingUpdate: handleBuildingUpdate, onUserVerifiedUpdate: handleUserVerifiedUpdate })))),
                React.createElement(Route, { exact: true, path: "/:mode(view|edit|multi-edit)", render: props => (React.createElement(Redirect, { to: `/${props.match.params.mode}/categories` })) }))),
        React.createElement(ColouringMap, { selectedBuildingId: selectedBuildingId, mode: mode || 'basic', category: displayCategory, revisionId: revisionId, onBuildingAction: mode === 'multi-edit' ? colourBuilding : selectBuilding })));
};
