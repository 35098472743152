import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../auth-context';
import ErrorBox from '../components/error-box';
import { SpinnerIcon } from '../components/icons';
import InfoBox from '../components/info-box';
import SupporterLogos from '../components/supporter-logos';
import i18next from "i18next";
import { useTranslation } from "react-i18next";
export const SignUp = () => {
    const { isLoading, signup } = useAuth();
    const [error, setError] = useState(undefined);
    const { t } = useTranslation();
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [confirmConditions, setConfirmConditions] = useState(false);
    const onSubmit = useCallback(e => {
        e.preventDefault();
        signup({ username, email, confirmEmail, password }, setError);
    }, [username, email, confirmEmail, password, confirmConditions, signup]);
    let signupText = 'Sign up';
    if (i18next.language === 'gr') {
        signupText = 'Εγγραφή';
    }
    return (React.createElement("article", null,
        React.createElement("section", { className: "main-col" },
            React.createElement("h1", { className: "h2" }, t('signup')),
            React.createElement(InfoBox, { msg: t('login_infobox') },
                React.createElement("br", null),
                t('login_please'),
                " ",
                React.createElement("a", { href: "https://discuss.colouring.london/" }, t('login_discuss_suggestions')),
                " ",
                t('login_and'),
                " ",
                React.createElement("a", { href: "https://github.com/colouring-cities/colouring-Athens/issues" }, t('login_report_issues')),
                "."),
            React.createElement("p", null, t('signup_create_an_account')),
            React.createElement(ErrorBox, { msg: error }),
            React.createElement("form", { onSubmit: onSubmit },
                React.createElement("label", { htmlFor: "username" }, t('login_username')),
                React.createElement("input", { name: "username", id: "username", className: "form-control", type: "text", value: username, onChange: e => setUsername(e.target.value), placeholder: t('login_not_your_real_name'), required: true, minLength: 4, maxLength: 30, pattern: "\\w+", title: t('signup_username_hint') }),
                React.createElement("label", { htmlFor: "email" }, t('signup_email_optional')),
                React.createElement("input", { name: "email", id: "email", className: "form-control", type: "email", value: email, onChange: e => setEmail(e.target.value), placeholder: "someone@example.com" }),
                React.createElement(InfoBox, { msg: t('signup_please_note') }),
                React.createElement("label", { htmlFor: "confirm_email" }, t('signup_confirm_email')),
                React.createElement("input", { name: "confirm_email", id: "confirm_email", className: "form-control", type: "email", value: confirmEmail, onChange: e => setConfirmEmail(e.target.value) }),
                React.createElement("label", { htmlFor: "password" }, t('signup_password')),
                React.createElement("input", { name: "password", id: "password", className: "form-control", type: (showPassword) ? 'text' : 'password', value: password, onChange: e => setPassword(e.target.value), required: true, minLength: 8, maxLength: 128 }),
                React.createElement("div", { className: "form-check" },
                    React.createElement("input", { id: "show_password", name: "show_password", className: "form-check-input", type: "checkbox", checked: showPassword, onChange: e => setShowPassword(e.target.checked) }),
                    React.createElement("label", { className: "form-check-label", htmlFor: "show_password" }, t('login_show_password'))),
                React.createElement("div", { className: "form-check" },
                    React.createElement("input", { id: "confirm_conditions", name: "confirm_conditions", className: "form-check-input", type: "checkbox", checked: confirmConditions, onChange: e => setConfirmConditions(e.target.checked), required: true }),
                    React.createElement("label", { className: "form-check-label", htmlFor: "confirm_conditions" },
                        t('signup_confirm_a'),
                        " ",
                        React.createElement(Link, { to: "/privacy-policy.html" }, t('signup_confirm_b')),
                        ", ",
                        React.createElement(Link, { to: "/contributor-agreement.html" }, t('signup_confirm_c')),
                        " ",
                        t('signup_confirm_d'),
                        " ",
                        React.createElement(Link, { to: "/data-accuracy.html" }, t('signup_confirm_e')),
                        ".")),
                React.createElement("div", { className: "buttons-container with-space" },
                    React.createElement("input", { type: "submit", disabled: isLoading, value: signupText, className: "btn btn-primary" }),
                    isLoading && React.createElement("span", null,
                        React.createElement(SpinnerIcon, null),
                        t('signup_sending'))),
                React.createElement(InfoBox, { msg: "" },
                    t('signup_data_ethics_a'),
                    " ",
                    React.createElement("a", { href: "https://www.pages.colouring.london/data-ethics" }, t('signup_data_ethics_b')),
                    " ",
                    t('signup_data_ethics_c')),
                t('signup_already_have_account'),
                React.createElement("div", { className: "buttons-container with-space" },
                    React.createElement(Link, { to: "login.html", className: "btn btn-outline-dark" }, t('login'))))),
        React.createElement("hr", null),
        React.createElement("section", { className: "main-col" },
            React.createElement(SupporterLogos, null))));
};
