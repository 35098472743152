import React from "react";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import "./welcome.css";
const Welcome = () => {
    return (React.createElement(Translation, null, (t) => (React.createElement("div", { className: "section-body welcome" },
        React.createElement("h1", { className: "h2" }, t("welcome_page_a")),
        React.createElement("p", null, t("welcome_page_b")),
        React.createElement("p", null, t("welcome_page_c")),
        React.createElement("p", null, t("welcome_page_d")),
        React.createElement("p", null,
            t("welcome_page_e"),
            " ",
            t("welcome_page_f"),
            " ",
            t("welcome_page_g"),
            " ",
            React.createElement("a", { href: "https://github.com/colouring-cities/colouring-Athens" }, t("welcome_page_h")),
            " ",
            t("welcome_page_i")),
        React.createElement(Link, { to: "/view/categories", className: "btn btn-outline-dark btn-lg btn-block" }, t("welcome_page_j")),
        React.createElement("div", { className: "image-row" },
            React.createElement("img", { className: "cl-logo", src: "images/logo-cc.jpg", alt: "Colouring Cities Research Programme" }),
            React.createElement("img", { className: "turing-logo", src: "images/logo-turing.jpg", alt: "Alan Turing Institute" })),
        React.createElement("div", { className: "image-row" },
            React.createElement("img", { className: "ntua-logo", src: "images/Logo_ntua_white.jpg", alt: "National Technical University of Athens" }),
            React.createElement("img", { className: "Athens-logo", src: "images/Composite_eata_logo_GR.png", alt: "Athens logo" }))))));
};
export default Welcome;
