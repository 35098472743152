import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "../auth-context";
import ErrorBox from "../components/error-box";
import { SpinnerIcon } from "../components/icons";
import InfoBox from "../components/info-box";
import SupporterLogos from "../components/supporter-logos";
import i18next from "i18next";
export const Login = () => {
    const { isLoading, login } = useAuth();
    const { t } = useTranslation();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState(undefined);
    const onSubmit = useCallback((e) => {
        e.preventDefault();
        setError(undefined);
        login({ username, password });
    }, [username, password]);
    let loginText = 'Log in';
    if (i18next.language === 'gr') {
        loginText = 'Σύνδεση';
    }
    return (React.createElement("article", null,
        React.createElement("section", { className: "main-col" },
            React.createElement("h1", { className: "h2" }, t('login')),
            React.createElement(InfoBox, { msg: t('login_infobox') },
                React.createElement("br", null),
                t('login_please'),
                " ",
                React.createElement("a", { href: "https://discuss.colouring.london/" }, t('login_discuss_suggestions')),
                " ",
                t('login_and'),
                " ",
                React.createElement("a", { href: "https://github.com/colouring-cities/colouring-Athens/issues" }, t('login_report_issues')),
                "."),
            React.createElement(ErrorBox, { msg: error }),
            React.createElement("form", { onSubmit: onSubmit },
                React.createElement("label", { htmlFor: "username" }, t('login_username')),
                React.createElement("input", { name: "username", id: "username", className: "form-control", type: "text", value: username, onChange: (e) => setUsername(e.target.value), placeholder: t('login_not_your_real_name'), required: true }),
                React.createElement("label", { htmlFor: "password" }, t('login_password')),
                React.createElement("input", { name: "password", id: "password", className: "form-control", type: showPassword ? "text" : "password", value: password, onChange: (e) => setPassword(e.target.value), required: true }),
                React.createElement("div", { className: "form-check" },
                    React.createElement("input", { id: "show_password", name: "show_password", className: "form-check-input", type: "checkbox", checked: showPassword, onChange: (e) => setShowPassword(e.target.checked) }),
                    React.createElement("label", { htmlFor: "show_password", className: "form-check-label" }, t('login_show_password'))),
                React.createElement(Link, { to: "/forgotten-password.html" }, t('login_forgotten_password')),
                React.createElement("div", { className: "buttons-container with-space" },
                    React.createElement("input", { type: "submit", disabled: isLoading, value: loginText, className: "btn btn-primary" }),
                    isLoading && (React.createElement("span", null,
                        React.createElement(SpinnerIcon, null),
                        t('login_logging_in')))),
                t('login_create_account'),
                React.createElement("div", { className: "buttons-container with-space" },
                    React.createElement(Link, { to: "sign-up.html", className: "btn btn-outline-dark" }, t('signup'))))),
        React.createElement("hr", null),
        React.createElement("section", { className: "main-col" },
            React.createElement(SupporterLogos, null))));
};
