import React from "react";
import ErrorBox from "../components/error-box";
import InfoBox from "../components/info-box";
import { Translation } from "react-i18next";
import i18next from "i18next";
export default class ForgottenPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: undefined,
            success: undefined,
            email: undefined,
            emailUsed: undefined,
        };
    }
    handleChange(event) {
        const { name, value } = event.currentTarget;
        this.setState({ [name]: value });
    }
    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ error: undefined, success: undefined });
        const emailSent = this.state.email;
        try {
            const res = await fetch("/api/users/password", {
                method: "PUT",
                body: JSON.stringify({ email: emailSent }),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = await res.json();
            if (data.error != undefined) {
                this.setState({ error: data.error });
            }
            else if (data.success === true) {
                this.setState({ success: true, emailUsed: emailSent });
            }
            else {
                this.setState({ error: "Unexpected result." });
            }
        }
        catch (err) {
            this.setState({ error: "Something went wrong." });
        }
    }
    render() {
        let requestText = "Request password reset";
        if (i18next.language === 'gr') {
            requestText = 'Ζητήστε επαναφορά κωδικού';
        }
        return (React.createElement(Translation, null, (t, { i18n }) => (React.createElement("article", null,
            React.createElement("section", { className: "main-col" },
                React.createElement("h1", { className: "h2" }, t('forgot_password')),
                React.createElement("p", null, t('forgot_password_desc')),
                React.createElement(ErrorBox, { msg: this.state.error }),
                React.createElement(InfoBox, { msg: "" }, this.state.success
                    ? `${t('forgot_password_success_a')} ${this.state.emailUsed}. ${t('forgot_password_success_b')}`
                    : null),
                React.createElement("form", { onSubmit: (e) => this.handleSubmit(e) },
                    React.createElement("label", { htmlFor: "email" }, "E-mail"),
                    React.createElement("input", { name: "email", id: "email", className: "form-control", type: "email", placeholder: t('forgot_password_email_desc'), required: true, onChange: (e) => this.handleChange(e) }),
                    React.createElement("div", { className: "buttons-container" },
                        React.createElement("input", { type: "submit", value: requestText, className: "btn btn-primary" }))))))));
    }
}
