import React, { Fragment } from 'react';
import { DataTitleCopyable } from './data-title';
const TextboxDataEntry = (props) => {
    return (React.createElement(Fragment, null,
        React.createElement(DataTitleCopyable, { slug: props.slug, title: props.title, tooltip: props.tooltip, disabled: props.disabled || props.value == undefined, copy: props.copy }),
        React.createElement("textarea", { className: "form-control", id: props.slug, name: props.slug, value: props.value || '', maxLength: props.maxLength, rows: 5, disabled: props.mode === 'view' || props.disabled, placeholder: props.placeholder, onChange: e => props.onChange(props.slug, e.target.value === '' ?
                null :
                e.target.value) })));
};
export default TextboxDataEntry;
