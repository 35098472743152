var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from './auth-context';
export const PrivateRoute = (_a) => {
    var { component: Component, children } = _a, rest = __rest(_a, ["component", "children"]);
    const { isAuthenticated } = useAuth();
    return React.createElement(Route, Object.assign({}, rest, { render: props => {
            if (isAuthenticated) {
                if (Component) {
                    return React.createElement(Component, Object.assign({}, props));
                }
                else if (children) {
                    return React.createElement(React.Fragment, null, children);
                }
            }
            else {
                return React.createElement(Redirect, { to: {
                        pathname: "/login.html",
                        state: { from: props.location.pathname }
                    } });
            }
        } }));
};
export const AuthRoute = (_a) => {
    var { component: Component, children } = _a, rest = __rest(_a, ["component", "children"]);
    const { isAuthenticated } = useAuth();
    return React.createElement(Route, Object.assign({}, rest, { render: props => {
            var _a;
            if (isAuthenticated) {
                const { from } = (_a = props.location.state) !== null && _a !== void 0 ? _a : { from: '/my-account.html' };
                return React.createElement(Redirect, { to: { pathname: from } });
            }
            else {
                if (Component) {
                    return React.createElement(Component, Object.assign({}, props));
                }
                else if (children) {
                    return React.createElement(React.Fragment, null, children);
                }
            }
        } }));
};
