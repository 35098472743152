import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
const CopyControl = (props) => {
    const { t } = useTranslation();
    return props.copying ? (React.createElement(React.Fragment, null,
        React.createElement(NavLink, { to: `/multi-edit/${props.cat}?data=${props.data_string}`, className: "icon-button copy" }, t("copy_selected")),
        React.createElement("a", { className: "icon-button copy", onClick: props.toggleCopying }, t("cancel")))) : (React.createElement("a", { className: "icon-button copy", onClick: props.toggleCopying }, t("copy")));
};
export { CopyControl };
