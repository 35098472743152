import React, { Fragment, useEffect, useState } from 'react';
import { apiGet } from '../../apiHelpers';
import { DataEntryInput } from './data-entry-input';
import { DataTitleCopyable } from './data-title';
import { useTranslation } from "react-i18next";
const DataEntryUse = (props) => {
    var _a, _b;
    const [options, setOptions] = useState(null);
    const { i18n } = useTranslation();
    useEffect(() => {
        const doAsync = async () => {
            const url = `/api/multilingualoptions?field_name=current_landuse_ground_floor&language=${i18n.language}`;
            const { options: newOptions } = await apiGet(url);
            setOptions(newOptions);
        };
        doAsync();
    }, [props.slug, i18n.language]);
    if (options == undefined)
        return null;
    return (React.createElement(Fragment, null,
        React.createElement(DataTitleCopyable, { slug: props.slug, slugModifier: props.slugModifier, title: props.title, tooltip: props.tooltip, 
            // disabled={props.disabled || props.value == undefined || props.value == ''}
            copy: props.copy }),
        React.createElement(DataEntryInput, { slug: props.slug, name: (_a = props.slug + props.slugModifier) !== null && _a !== void 0 ? _a : '', value: (_b = options[(props === null || props === void 0 ? void 0 : props.value) - 1]) === null || _b === void 0 ? void 0 : _b.label, 
            // onChange={props.onChange}
            disabled: true, maxLength: props.maxLength, placeholder: props.placeholder, isUrl: props.isUrl, required: props.required })));
};
export default DataEntryUse;
