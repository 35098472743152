import React from 'react';
import { useTranslation } from "react-i18next";
import { DataTitleCopyable } from '../data-title';
const ToggleButton = ({ value, checked, disabled, onChange, checkedClassName, uncheckedClassName, children }) => {
    return (
    /**
     * If the toggle button is both checked and disabled, we can't set disabled CSS class
     * because then Bootstrap makes the button look like it wasn't checked.
     * So we skip the class and force cursor type manually so it doesn't look clickable.
     */
    React.createElement("label", Object.assign({ className: `btn ${checked ? checkedClassName : uncheckedClassName} ${disabled && !checked && 'disabled'}` }, disabled && checked && {
        style: { cursor: 'default' }
    }),
        React.createElement("input", { type: "radio", name: "options", value: value + '', autoComplete: "off", checked: checked, onChange: onChange, disabled: disabled }),
        children));
};
const ClearButton = ({ onClick, disabled }) => {
    const { t } = useTranslation();
    return React.createElement("div", { className: "btn-group btn-group-toggle" },
        React.createElement("label", null,
            React.createElement("button", { type: "button", className: "btn btn-outline-warning", onClick: onClick, disabled: disabled }, t("clear"))));
};
export const LogicalDataEntry = (props) => {
    function handleValueChange(e) {
        var _a;
        (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, props.slug, e.target.value === 'true');
    }
    function handleClear(e) {
        var _a;
        (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, props.slug, null);
    }
    const isDisabled = props.mode === 'view' || props.disabled;
    const { t } = useTranslation();
    return (React.createElement(React.Fragment, null,
        React.createElement(DataTitleCopyable, { slug: props.slug, title: props.title, tooltip: props.tooltip, disabled: props.disabled || props.value == undefined, copy: props.copy }),
        React.createElement("div", { className: "btn-group btn-group-toggle" },
            React.createElement(ToggleButton, { value: "true", checked: props.value === true, disabled: isDisabled || props.disallowTrue, checkedClassName: 'btn-outline-dark active', uncheckedClassName: 'btn-outline-dark', onChange: handleValueChange }, t("yes")),
            React.createElement(ToggleButton, { value: "false", checked: props.value === false, disabled: isDisabled || props.disallowFalse, checkedClassName: 'btn-outline-dark active', uncheckedClassName: 'btn-outline-dark', onChange: handleValueChange }, t("no"))),
        !isDisabled && props.value != null &&
            React.createElement(ClearButton, { onClick: handleClear, disabled: props.disallowNull })));
};
