/**
 * Client-side entry point to shared frontend React App
 *
 */
import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { App } from './frontend/app';
import "./i18n";
const data = window.__PRELOADED_STATE__; // TODO: remove any
hydrate(React.createElement(BrowserRouter, null,
    React.createElement(App, { user: data.user, building: data.building, user_verified: data.user_verified, revisionId: data.latestRevisionId })), document.getElementById('root'));
if (module.hot) {
    module.hot.accept();
}
