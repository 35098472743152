import React from "react";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import ErrorBox from "../components/error-box";
import i18next from "i18next";
export default class PasswordReset extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: undefined,
            success: undefined,
            token: undefined,
            password: undefined,
            confirmPassword: undefined,
        };
    }
    componentDidMount() {
        const queryParams = new URLSearchParams(this.props.location.search);
        const token = queryParams.get("token");
        if (token == undefined) {
            this.setState({ error: PasswordReset.tokenMissingMessage });
        }
        else {
            this.setState({ token: token });
        }
    }
    handleChange(event) {
        const { name, value } = event.currentTarget;
        this.setState({
            [name]: value,
        });
    }
    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ error: undefined });
        if (this.state.token == undefined) {
            this.setState({ error: PasswordReset.tokenMissingMessage });
            return;
        }
        if (this.state.password !== this.state.confirmPassword) {
            this.setState({ error: "Passwords do not match" });
            return;
        }
        // const { t } = useTranslation();
        const requestData = {
            token: this.state.token,
            password: this.state.password,
        };
        const res = await fetch("/api/users/password", {
            method: "PUT",
            body: JSON.stringify(requestData),
            headers: {
                "Content-Type": "application/json",
            },
        });
        const data = await res.json();
        if (data.error != undefined) {
            this.setState({ error: data.error });
        }
        else if (data.success === true) {
            this.setState({ success: true });
        }
        else {
            this.setState({ error: "Unexpected result." });
        }
    }
    render() {
        if (this.state.success) {
            return React.createElement(Redirect, { to: "/my-account.html" });
        }
        let changePassword = "Change password";
        let newPassword = "New password";
        let confirmNewPassword = "Confirm new password";
        if (i18next.language === 'gr') {
            changePassword = "Αλλαγή κωδικού";
            newPassword = "Νέος κωδικός";
            confirmNewPassword = "Επιβεβαίωση νέου κωδικού";
        }
        return (React.createElement(Translation, null, (t, { i18n }) => (React.createElement("article", null,
            React.createElement("section", { className: "main-col" },
                React.createElement("h1", { className: "h2" }, t('forgot_password')),
                React.createElement("p", null,
                    t('forgotten_password_text_a'),
                    " ",
                    React.createElement(Link, { to: "/forgotten-password.html" }, t('forgotten_password_text_b')),
                    " ",
                    t('forgotten_password_text_c')),
                React.createElement(ErrorBox, { msg: this.state.error }),
                React.createElement("form", { onSubmit: (e) => this.handleSubmit(e) },
                    React.createElement("label", { htmlFor: "email" }, t('forgotten_password_text_d')),
                    React.createElement("input", { name: "password", className: "form-control", type: "password", placeholder: newPassword, required: true, onChange: (e) => this.handleChange(e) }),
                    React.createElement("label", { htmlFor: "email" }, t('forgotten_password_text_e')),
                    React.createElement("input", { name: "confirmPassword", className: "form-control", type: "password", placeholder: confirmNewPassword, required: true, onChange: (e) => this.handleChange(e) }),
                    React.createElement("input", { name: "token", id: "token", type: "hidden", value: this.state.token }),
                    React.createElement("div", { className: "buttons-container" },
                        React.createElement("input", { type: "submit", value: changePassword, className: "btn btn-primary" }))))))));
    }
}
PasswordReset.tokenMissingMessage = "Password reset token is missing. Make sure to follow the link from a password reset email!";
