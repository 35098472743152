import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { EditIcon, ViewIcon } from "../../components/icons";
const ViewEditControl = (props) => {
    const { t } = useTranslation();
    return props.mode === "edit" ? (React.createElement(NavLink, { className: "icon-button view", title: "View data", to: `/view/${props.cat}/${props.building.building_id}` },
        t("view"),
        React.createElement(ViewIcon, null))) : (React.createElement(NavLink, { className: "icon-button edit", title: "Edit data", to: `/edit/${props.cat}/${props.building.building_id}` },
        t("edit"),
        React.createElement(EditIcon, null)));
};
export { ViewEditControl };
