import React, { Fragment, useState, useEffect } from "react";
import { VerifyIcon } from "../../components/icons";
import { apiGet } from "../../apiHelpers";
import { useTranslation } from "react-i18next";
import "./verification.css";
const SelectDataEntryMultiLingual = (props) => {
    //   const slugWithModifier = props.slug + (props.slugModifier ?? "");
    const [options, setLabel] = useState(null);
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    function handleClick(verify) {
        return (e) => {
            e.preventDefault();
            const x = e.clientX / document.body.clientWidth;
            const y = e.clientY / document.body.clientHeight;
            props.onVerify(props.slug, verify, x, y);
        };
    }
    let displayedVerifiedValue = "";
    useEffect(() => {
        const doAsync = async () => {
            if (typeof props.user_verified_as !== "undefined") {
                const url = `/api/multilinguallabels?field_name=${props.slug}&language=${i18n.language}&id=${props.user_verified_as}`;
                const { options: newOptions } = await apiGet(url);
                setLabel(newOptions);
            }
        };
        doAsync();
    }, [props.slug, props.user_verified_as, i18n.language]);
    if (options == undefined) {
        displayedVerifiedValue = "";
    }
    else {
        displayedVerifiedValue = options[0].label;
    }
    return (React.createElement("div", { className: "verification-container" },
        React.createElement("span", { className: "verification-status", title: `Verified by ${props.verified_count} ${props.verified_count == 1 ? "person" : "people"}` },
            React.createElement(VerifyIcon, null),
            props.verified_count || 0),
        props.user_verified ? (React.createElement(Fragment, null,
            t("verified_as"),
            " \"",
            React.createElement("span", null, displayedVerifiedValue),
            "\"",
            React.createElement("button", { className: "btn btn-danger", title: "Remove my verification", disabled: !props.allow_verify, onClick: handleClick(false) }, t("verified_remove")))) : (React.createElement(Fragment, null,
            React.createElement("button", { className: "btn btn-success", title: "Confirm that the current value is correct", disabled: !props.allow_verify, onClick: handleClick(true) }, t("verify"))))));
};
export default SelectDataEntryMultiLingual;
