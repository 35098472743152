import React from 'react';
import { Link } from 'react-router-dom';
import InfoBox from '../../../components/info-box';
import { Category } from '../../../config/categories-config';
import { dataFields } from '../../../config/data-fields-config';
import DataEntry from '../../data-components/data-entry';
import { DataEntryGroup } from '../../data-components/data-entry-group';
import { DynamicsBuildingPane, DynamicsDataEntry } from './dynamics-data-entry';
import { FieldRow } from '../../data-components/field-row';
import NumericDataEntry from '../../data-components/numeric-data-entry';
import withCopyEdit from '../../data-container';
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { LogicalDataEntry } from '../../data-components/logical-data-entry/logical-data-entry';
/**
* Dynamics view/edit section
*/
const DynamicsView = (props) => {
    var _a, _b, _c, _d;
    const building = props.building;
    const thisYear = (new Date()).getFullYear();
    const currentBuildingConstructionYear = building.date_year || undefined;
    const ageLinkUrl = `/${props.mode}/${Category.Age}/${props.building.building_id}`;
    const { t } = useTranslation();
    let year_constructed_title = dataFields.demolished_buildings.items.year_constructed.title_en;
    let year_demolished_title = dataFields.demolished_buildings.items.year_constructed.title_en;
    let dynamics_has_demolished_buildings_title = dataFields.dynamics_has_demolished_buildings.title_en;
    if (i18next.language === "gr") {
        year_constructed_title = dataFields.demolished_buildings.items.year_constructed.title_gr;
        year_demolished_title = dataFields.demolished_buildings.items.year_constructed.title_gr;
        dynamics_has_demolished_buildings_title = dataFields.dynamics_has_demolished_buildings.title_gr;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(DataEntryGroup, { collapsed: false, name: t("dynamics_info_a"), showCount: false },
            React.createElement(DynamicsBuildingPane, null,
                React.createElement("label", null,
                    t("dynamics_info_b"),
                    React.createElement(Link, { to: ageLinkUrl }, t("dynamics_info_c")),
                    ")"),
                React.createElement(FieldRow, null,
                    React.createElement("div", null,
                        React.createElement(NumericDataEntry, { slug: '', title: year_constructed_title, value: currentBuildingConstructionYear, disabled: true, mode: 'view' })),
                    React.createElement("div", null,
                        React.createElement(NumericDataEntry, { slug: '', title: year_demolished_title, value: undefined, placeholder: '---', disabled: true, mode: 'view' })),
                    React.createElement("div", { style: { flex: '0 1 27%' } },
                        React.createElement(DataEntry, { slug: '', title: t("dynamics_info_d"), value: (thisYear - currentBuildingConstructionYear) + '', disabled: true, mode: 'view' })))),
            currentBuildingConstructionYear == undefined ?
                React.createElement(InfoBox, null,
                    t("dynamics_info_e"),
                    React.createElement(Link, { to: ageLinkUrl }, t("dynamics_info_f")),
                    t("dynamics_info_g")) :
                React.createElement(React.Fragment, null,
                    React.createElement(LogicalDataEntry, { slug: 'dynamics_has_demolished_buildings', title: dynamics_has_demolished_buildings_title, value: building.dynamics_has_demolished_buildings, disallowFalse: ((_b = (_a = building.demolished_buildings) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) > 0, disallowNull: ((_d = (_c = building.demolished_buildings) === null || _c === void 0 ? void 0 : _c.length) !== null && _d !== void 0 ? _d : 0) > 0, onChange: props.onSaveChange, mode: props.mode, copy: props.copy }),
                    building.dynamics_has_demolished_buildings &&
                        React.createElement(React.Fragment, null,
                            React.createElement(DynamicsDataEntry, { key: building.building_id, value: building.demolished_buildings, editableEntries: true, slug: 'demolished_buildings', title: dataFields.demolished_buildings.title, mode: props.mode, onChange: props.onChange, onSaveAdd: props.onSaveAdd, hasEdits: props.edited, maxYear: currentBuildingConstructionYear, minYear: 50 }),
                            props.mode === 'view' &&
                                React.createElement(InfoBox, null, t("dynamics_info_h"))))),
        React.createElement(InfoBox, null,
            t("dynamics_info_i"),
            React.createElement("a", { href: "https://discuss.colouring.london/t/dynamics-category-discussion/107" }, t("dynamics_info_j")),
            t("dynamics_info_k"))));
};
const DynamicsContainer = withCopyEdit(DynamicsView);
export default DynamicsContainer;
