import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../auth-context';
import ConfirmationModal from '../components/confirmation-modal';
import ErrorBox from '../components/error-box';
import { SpinnerIcon } from '../components/icons';
import { useTranslation } from "react-i18next";
import i18next from "i18next";
export const MyAccountPage = () => {
    const { isLoading, user, userError, logout, generateApiKey, deleteAccount } = useAuth();
    const { t } = useTranslation();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [error, setError] = useState(undefined);
    const handleLogout = useCallback((e) => {
        e.preventDefault();
        logout(setError);
    }, [logout]);
    const handleGenerateKey = useCallback(async (e) => {
        e.preventDefault();
        setError(undefined);
        generateApiKey(setError);
    }, [generateApiKey]);
    const handleDeleteAccount = useCallback(() => {
        setError(undefined);
        deleteAccount(setError);
    }, [deleteAccount]);
    if (!user && isLoading) {
        return (React.createElement("article", null,
            React.createElement("section", { className: "main-col" },
                React.createElement(SpinnerIcon, { spin: true }),
                " ",
                t('account_loading'))));
    }
    let deleteAccountText = "Delete account";
    let logoutText = "Log out";
    let generateApiKeyText = "Generate API key";
    if (i18next.language === 'gr') {
        deleteAccountText = 'Διαγραφή λογαριασμού';
        logoutText = "Αποσύνδεση";
        generateApiKeyText = "Δημιουργία κλειδιού API";
    }
    return (React.createElement("article", null,
        React.createElement("section", { className: "main-col" },
            !isLoading && React.createElement(ErrorBox, { msg: userError }),
            !userError && (React.createElement(React.Fragment, null,
                React.createElement("h1", { className: "h1" },
                    t('account_welcome'),
                    " ",
                    user.username,
                    "!"),
                React.createElement("p", null,
                    t('account_active_development'),
                    ' ',
                    React.createElement("a", { href: "https://discuss.colouring.london/" }, t('login_discuss_suggestions')),
                    " ",
                    t('login_and'),
                    ' ',
                    React.createElement("a", { href: "https://github.com/colouring-cities/colouring-Athens/issues" },
                        " ",
                        t('login_report_issues')),
                    "."),
                React.createElement("p", null,
                    t('account_for_reference'),
                    ' ',
                    React.createElement(Link, { to: "/privacy-policy.html" }, t('account_privacy_policy')),
                    ",",
                    ' ',
                    React.createElement(Link, { to: "/contributor-agreement.html" }, t('account_contributor_agreement')),
                    " ",
                    t('account_and'),
                    ' ',
                    React.createElement(Link, { to: "/data-accuracy.html" }, t('account_data_accuracy_agreement')),
                    "."),
                React.createElement(ErrorBox, { msg: error }),
                React.createElement("form", { onSubmit: handleLogout },
                    React.createElement("div", { className: "buttons-container" },
                        React.createElement(Link, { to: "/edit/age", className: "btn btn-warning" }, t('account_start_colouring')),
                        React.createElement("input", { className: "btn btn-secondary", type: "submit", value: logoutText }))),
                React.createElement("hr", null),
                React.createElement("h2", { className: "h2" }, t('account_my_details')),
                React.createElement("h3", { className: "h3" }, t('account_username')),
                React.createElement("p", null, user.username),
                React.createElement("h3", { className: "h3" }, t('account_email')),
                React.createElement("p", null, user.email || '-'),
                React.createElement("h3", { className: "h3" }, t('account_registered')),
                React.createElement("p", null, user.registered.toString()),
                React.createElement("hr", null),
                React.createElement("h2", { className: "h2" }, t('account_technical_details')),
                React.createElement("p", null, t('account_are_you_a_developer')),
                React.createElement("h3", { className: "h3" }, t('account_api_key')),
                React.createElement("p", null, user.api_key || '-'),
                React.createElement("form", { onSubmit: handleGenerateKey, className: "form-group mb-3" },
                    React.createElement("input", { className: "btn btn-warning", type: "submit", value: generateApiKeyText })),
                React.createElement("h3", { className: "h3" }, t('account_open_source_code')),
                t('account_site_code'),
                " ",
                React.createElement("a", { href: "https://github.com/colouring-cities/colouring-Athens/" }, "colouring-Athens"),
                " ",
                t('account_github'),
                React.createElement("hr", null),
                React.createElement("h2", { className: "h2" }, t('account_actions')),
                React.createElement("form", { onSubmit: e => {
                        e.preventDefault();
                        setShowDeleteConfirm(true);
                    }, className: "form-group mb-3" },
                    React.createElement("input", { className: "btn btn-danger", type: "submit", value: deleteAccountText })),
                React.createElement(ConfirmationModal, { show: showDeleteConfirm, title: t('account_delete_confirm'), description: t('account_delete_confirm_desc'), confirmButtonText: "Delete account", confirmButtonClass: "btn-danger", onConfirm: () => handleDeleteAccount(), onCancel: () => setShowDeleteConfirm(false) }))))));
};
