import React, { Component } from "react";
import { Translation } from "react-i18next";
class LeaderboardPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            leaders: [],
            fetching: false,
            number_limit: 10,
            time_limit: -1,
        };
        this.getLeaders = this.getLeaders.bind(this);
        this.renderTableData = this.renderTableData.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        if (e.target.name == "number_limit") {
            this.getLeaders(e.target.value, this.state.time_limit);
            this.setState({ number_limit: e.target.value });
        }
        else {
            this.getLeaders(this.state.number_limit, e.target.value);
            this.setState({ time_limit: e.target.value });
        }
    }
    componentDidMount() {
        this.getLeaders(this.state.number_limit, this.state.time_limit);
    }
    getLeaders(number_limit, time_limit) {
        this.setState({
            fetching: true,
        });
        fetch(`/api/leaderboard/leaders?number_limit=${number_limit}&time_limit=${time_limit}`)
            .then((res) => res.json())
            .then((data) => {
            if (data && data.leaders) {
                this.setState({
                    leaders: data.leaders,
                    fetching: false,
                });
            }
            else {
                console.error(data);
                this.setState({
                    leaders: [],
                    fetching: false,
                });
            }
        })
            .catch((err) => {
            console.error(err);
            this.setState({
                leaders: [],
                fetching: false,
            });
        });
    }
    renderTableData() {
        return this.state.leaders.map((u, i) => {
            const username = u.username;
            const number_edits = u.number_edits;
            return (React.createElement("tr", { key: username },
                React.createElement("th", { scope: "row" }, i + 1),
                React.createElement("td", null, username),
                React.createElement("td", null, number_edits.toLocaleString())));
        });
    }
    render() {
        return (React.createElement(Translation, null, (t, { i18n }) => (React.createElement("article", null,
            React.createElement("section", { className: "main-col" },
                React.createElement("h1", { className: "h2" }, t('leaderboard')),
                React.createElement("form", null,
                    React.createElement("label", null, t('select_number_of_users')),
                    React.createElement("div", { className: "form-group" },
                        React.createElement("div", { className: "form-check-inline" },
                            React.createElement("input", { type: "radio", name: "number_limit", id: "number_10", className: "form-check-input", value: "10", onChange: this.handleChange, checked: 10 == this.state.number_limit }),
                            React.createElement("label", { className: "form-check-label", htmlFor: "number_10" }, "10")),
                        React.createElement("div", { className: "form-check-inline" },
                            React.createElement("input", { type: "radio", name: "number_limit", id: "number_100", className: "form-check-input", value: "100", onChange: this.handleChange, checked: 100 == this.state.number_limit }),
                            React.createElement("label", { className: "form-check-label", htmlFor: "number_100" }, "100"))),
                    React.createElement("label", null, t('select_time_period')),
                    React.createElement("div", { className: "form-group" },
                        React.createElement("div", { className: "form-check-inline" },
                            React.createElement("input", { type: "radio", name: "time_limit", id: "time_all", className: "form-check-input", value: "-1", onChange: this.handleChange, checked: -1 == this.state.time_limit }),
                            React.createElement("label", { className: "form-check-label", htmlFor: "time_all" }, t('all_time'))),
                        React.createElement("div", { className: "form-check-inline" },
                            React.createElement("input", { type: "radio", name: "time_limit", id: "time_7", className: "form-check-input", value: "7", onChange: this.handleChange, checked: 7 == this.state.time_limit }),
                            React.createElement("label", { className: "form-check-label", htmlFor: "time_7" }, t('last_7days'))),
                        React.createElement("div", { className: "form-check-inline" },
                            React.createElement("input", { type: "radio", name: "time_limit", id: "time_30", className: "form-check-input", value: "30", onChange: this.handleChange, checked: 30 == this.state.time_limit }),
                            React.createElement("label", { className: "form-check-label", htmlFor: "time_30" }, t('last_30days'))))),
                React.createElement("table", { className: "table table-striped" },
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", { scope: "col" }, "#"),
                            React.createElement("th", { scope: "col" },
                                " ",
                                t('login_username')),
                            React.createElement("th", { scope: "col" },
                                " ",
                                t('contributions')))),
                    React.createElement("tbody", null, this.renderTableData())))))));
    }
}
export default LeaderboardPage;
