import React, { Fragment } from "react";
import InfoBox from "../../components/info-box";
import withCopyEdit from "../data-container";
import { useTranslation } from "react-i18next";
/**
 * Team view/edit section
 */
const TeamView = (props) => {
    const { t } = useTranslation();
    return (React.createElement(Fragment, null,
        React.createElement(InfoBox, { msg: t("team_message_a") }),
        React.createElement(InfoBox, { msg: t("team_message_b") }),
        React.createElement("ul", null,
            React.createElement("li", null, t("team_ul_a")),
            React.createElement("li", null, t("team_ul_b")),
            React.createElement("li", null, t("team_ul_c")))));
};
const TeamContainer = withCopyEdit(TeamView);
export default TeamContainer;
