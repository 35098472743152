import React from "react";
import { Link } from "react-router-dom";
import "./building-edit-summary.css";
import { dataFields, } from "../../config/data-fields-config";
import { arrayToDictionary, parseDate } from "../../helpers";
import { CategoryEditSummary } from "./category-edit-summary";
import { Translation } from "react-i18next";
function formatDate(dt) {
    return dt.toLocaleString(undefined, {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
    });
}
function enrichHistoryEntries(forwardPatch, reversePatch) {
    // if (i18next.language === 'gr') {
    return Object.entries(forwardPatch).map(([key, value]) => {
        var _a;
        const { slug = undefined, multilingual = false, title_en = `Unknown field (${key})`, title_gr = `Άγνωστο πεδίο (${key})`, category = undefined, } = (_a = dataFields[key]) !== null && _a !== void 0 ? _a : {};
        return {
            slug,
            multilingual,
            title_en,
            title_gr,
            category,
            value,
            oldValue: reversePatch && reversePatch[key],
        };
    });
}
const BuildingEditSummary = ({ historyEntry, showBuildingId = false, hyperlinkCategories = false }) => {
    const entriesWithMetadata = enrichHistoryEntries(historyEntry.forward_patch, historyEntry.reverse_patch);
    const entriesByCategory = arrayToDictionary(entriesWithMetadata, (x) => x.category);
    const categoryHyperlinkTemplate = hyperlinkCategories && historyEntry.building_id != undefined
        ? `/edit/$category/${historyEntry.building_id}`
        : undefined;
    return (React.createElement(Translation, null, (t, { i18n }) => (React.createElement("div", { className: "edit-history-entry" },
        React.createElement("h2", { className: "edit-history-timestamp" },
            t('edited_on'),
            " ",
            formatDate(parseDate(historyEntry.revision_timestamp))),
        React.createElement("h3", { className: "edit-history-username" },
            t('edited_by'),
            " ",
            historyEntry.username),
        showBuildingId && historyEntry.building_id != undefined && (React.createElement("h3", { className: "edit-history-building-id" },
            t('edited_building'),
            " ",
            React.createElement(Link, { to: `/edit/categories/${historyEntry.building_id}` }, historyEntry.building_id))),
        Object.entries(entriesByCategory).map(([category, fields]) => (React.createElement(CategoryEditSummary, { key: category, category: category, fields: fields, hyperlinkCategory: hyperlinkCategories, hyperlinkTemplate: categoryHyperlinkTemplate })))))));
};
export { BuildingEditSummary };
