import React, { Fragment, useEffect, useState } from "react";
import { DataTitleCopyable } from "./data-title";
import { apiGet } from "../../apiHelpers";
import { useTranslation } from "react-i18next";
const SelectDataEntryMultiLingual = (props) => {
    var _a;
    const slugWithModifier = props.slug + ((_a = props.slugModifier) !== null && _a !== void 0 ? _a : "");
    const [options, setOptions] = useState(null);
    const { i18n } = useTranslation();
    useEffect(() => {
        // let unmounted = false;
        // async function doAsync() {
        const doAsync = async () => {
            const url = `/api/multilingualoptions?field_name=${props.slug}&language=${i18n.language}`;
            const { options: newOptions } = await apiGet(url);
            setOptions(newOptions);
        };
        // if (!unmounted) {
        doAsync();
        // }
        // return () => {
        //   unmounted = true;
        // };
    }, [props.slug, i18n.language]);
    if (options == undefined)
        return null;
    options.sort((a, b) => a.value - b.value);
    return (React.createElement(Fragment, null,
        React.createElement(DataTitleCopyable, { slug: props.slug, slugModifier: props.slugModifier, title: props.title, tooltip: props.tooltip, disabled: props.disabled || props.value == undefined, copy: props.copy }),
        React.createElement("select", { className: "form-control", id: slugWithModifier, name: slugWithModifier, value: props.value || '', disabled: props.mode === "view" || props.disabled, required: props.required, onChange: (e) => props.onChange(props.slug, e.target.value === null ? null : e.target.value) },
            React.createElement("option", { value: "" }, props.placeholder),
            options.map((option) => (React.createElement("option", { key: option.value, value: option.value }, option.label))))));
};
export default SelectDataEntryMultiLingual;
