import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./category-edit-summary.css";
import { categoriesConfig } from "../../config/categories-config";
import { Translation } from "react-i18next";
import { FieldEditSummary } from "./field-edit-summary";
import { apiGet } from "../../apiHelpers";
const CategoryEditSummary = (props) => {
    var _a;
    const { name_en: categoryNameEN = "Unknown category", name_gr: categoryNameGR = "Άγνωστη κατηγορία", slug: categorySlug = "categories", } = (_a = categoriesConfig[props.category]) !== null && _a !== void 0 ? _a : {};
    return (React.createElement(Translation, null, (t, { i18n }) => (React.createElement("div", { className: "edit-history-category-summary" },
        React.createElement("h3", { className: "edit-history-category-title" },
            props.hyperlinkCategory && props.hyperlinkTemplate != undefined ? (React.createElement(Link, { to: props.hyperlinkTemplate.replace(/\$category/, categorySlug) }, i18n.language === "gr" ? categoryNameGR : categoryNameEN)) : i18n.language === "gr" ? (categoryNameGR) : (categoryNameEN),
            ":"),
        React.createElement("ul", null, props.fields.map((x) => {
            const [oldValue, setOldLabel] = useState(null);
            const [value, setLabel] = useState(null);
            let displayedOldValue = "";
            let displayedValue = "";
            useEffect(() => {
                const doAsync = async () => {
                    // console.log(x.multilingual)
                    // console.log(x.oldValue)
                    // console.log(x.slug)
                    if (x.multilingual &&
                        typeof x.slug !== "undefined" &&
                        typeof x.oldValue !== "undefined" &&
                        x.oldValue !== null) {
                        const urlOldValue = `/api/multilinguallabels?field_name=${x.slug}&language=${i18n.language}&id=${x.oldValue}`;
                        const { options: oldValue } = await apiGet(urlOldValue);
                        setOldLabel(oldValue);
                    }
                    if (x.multilingual &&
                        typeof x.slug !== "undefined" &&
                        typeof x.value !== "undefined" &&
                        x.value !== null) {
                        const urlValue = `/api/multilinguallabels?field_name=${x.slug}&language=${i18n.language}&id=${x.value}`;
                        const { options: valueLabel } = await apiGet(urlValue);
                        setLabel(valueLabel);
                    }
                };
                doAsync();
            }, [x.slug, x.oldValue, x.value, i18n.language]);
            if (oldValue != undefined) {
                displayedOldValue = oldValue[0].label;
            }
            if (value != undefined) {
                displayedValue = value[0].label;
            }
            return (React.createElement("li", { key: i18n.language === "gr" ? x.title_gr : x.title_en },
                React.createElement(FieldEditSummary, { title: i18n.language === "gr" ? x.title_gr : x.title_en, 
                    // value={x.value}
                    // oldValue={x.oldValue}
                    value: x.multilingual ? displayedValue : x.value, oldValue: x.multilingual ? displayedOldValue : x.oldValue })));
        }))))));
};
export { CategoryEditSummary };
