import React, { Fragment } from 'react';
import { DataTitleCopyable } from './data-title';
const NumericDataEntry = (props) => {
    var _a;
    const slugWithModifier = props.slug + ((_a = props.slugModifier) !== null && _a !== void 0 ? _a : '');
    return (React.createElement(Fragment, null,
        React.createElement(DataTitleCopyable, { slug: props.slug, slugModifier: props.slugModifier, title: props.title, tooltip: props.tooltip, disabled: props.disabled || props.value == undefined, copy: props.copy }),
        React.createElement("input", { className: "form-control", type: "text", id: slugWithModifier, name: slugWithModifier, value: props.value == undefined ? '' : props.value, step: props.step == undefined ? 1 : props.step, max: props.max, min: props.min, maxLength: props.maxLength, disabled: props.mode === 'view' || props.disabled, placeholder: props.placeholder, required: props.required, onChange: e => props.onChange(props.slug, e.target.value === '' ? null : parseFloat(e.target.value)) })));
};
export default NumericDataEntry;
